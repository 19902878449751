import React from 'react'
import { themeGet, Box, Text } from '@primer/components'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBack'

const ButtonWrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: ${themeGet('space.2')};
  padding-bottom: ${themeGet('space.2')};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: calc(100% - (${themeGet('space.2')} * 2));
    width: 100%;
    z-index: -1;
    transition: background 100ms ease;
  }

  &:hover::before,
  &:focus::before {
    background: ${themeGet('colors.primary')};
  }

  & * {
    transition: color 100ms ease;
  }
`

const Arrow = styled(Box)`
  transform: 'scale(1)';
  color: ${themeGet('colors.bodytext')};

  a:hover &,
  a:focus & {
    color: ${themeGet('colors.white')};
  }
`

const ButtonText = styled(Text)`
  letter-spacing: 0.05em;
  text-decoration: none;

  a:hover &,
  a:focus & {
    color: ${themeGet('colors.white')};
  }
`

const BackPageButton = ({ text, to }) => {
  return (
    <ButtonWrapper to={to}>
      <Arrow as={ArrowBack} ml="-0.15em" mr={1} />
      <ButtonText fontWeight="bold" fontSize={[2]} color="bodytext" mx={2}>
        Back to {text}
      </ButtonText>
    </ButtonWrapper>
  )
}

export default BackPageButton
