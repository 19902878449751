import React from 'react'
import { graphql } from 'gatsby'
import { themeGet, Box, Heading, Text, Absolute } from '@primer/components'
import styled from 'styled-components'

import PageWrapper from '../components/layout/PageWrapper'
import SEO from '../components/reusable/Seo'
import CovertArt from '../components/reusable/CovertArt'
import MusicItemActions from '../components/page/music/MusicItemActions'
import BackPageButton from '../components/reusable/BackPageButton'

const SongWrapper = styled(Box)`
  width: 100%;
  hyphens: auto;

  @media (min-width: ${themeGet('breakpoints.0')}) {
    width: 87vw;
    max-width: 450px;
  }
`

export default ({ data }) => {
  const song = data.musicYaml
  return (
    <>
      <SEO title={song.title} />

      <Absolute
        left={0}
        top={0}
        ml={[4, 6]}
        mt={[9, 9, 9, 9]}
        pt={[0, 0, 0, 2]}
        style={{ zIndex: 999 }}
      >
        <BackPageButton text="music" to="/music" />
      </Absolute>

      <PageWrapper pt={[12, 12, 12, 10]} mt={[4, 4, 4, 0]} pb={10}>
        <SongWrapper>
          <CovertArt imgName={song.imgName} />

          <Box as="header" mt={[4, 4, 4, 6]} mb={[7, 8]}>
            <Heading as="h1" fontSize={[4, 5]}>
              {song.title}
            </Heading>
            <Text
              as="h2"
              color="accent.2"
              fontWeight="light"
              fontSize={[2, 4]}
              mt={0}
            >
              Acknosyn
            </Text>
          </Box>

          <MusicItemActions songLinks={song.links} />
        </SongWrapper>
      </PageWrapper>
    </>
  )
}

// StaticQuery doesn't support variables and filters
// $slug is passed through page context - gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    musicYaml(fields: { slugPath: { eq: $slug } }) {
      title
      imgName
      release
      links {
        spotify
        appleMusic
        googleMusic
        soundcloud
        tidal
        amazonMusic
        deezer
        napster
        shazam
        youtubeMusic
      }
      fields {
        slugPath
      }
    }
  }
`
