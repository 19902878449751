import React, { Component } from 'react'
import { themeGet, Box, Text, Flex } from '@primer/components'
import styled from 'styled-components'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

const TitleDefault = styled(Box)`
  border-top: 1px solid ${themeGet('colors.accent.1')};
  border-bottom: 1px solid ${themeGet('colors.accent.1')};
`

const TitleMoreSection = styled(Box)`
  border-top: 1px solid ${themeGet('colors.accent.1')};
  border-bottom: 1px solid ${themeGet('colors.accent.1')};
  cursor: default;

  :hover {
    background-color: ${themeGet('colors.accent.1')};
    color: ${themeGet('colors.bodytext')};
  }
`

const TitleMore = styled(Text)`
  .more-title-section:hover & {
    color: ${themeGet('colors.bodytext')};
  }
`

const StyledExpand = styled(ExpandMore)`
  color: ${themeGet('colors.accent.4')};

  .more-title-section:hover & {
    color: ${themeGet('colors.bodytext')};
  }
`

const StyledCollapse = styled(ExpandLess)`
  color: ${themeGet('colors.accent.4')};

  .more-title-section:hover & {
    color: ${themeGet('colors.bodytext')};
  }
`

export default class MusicActionsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }

    this.toggleExpand = this.toggleExpand.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  toggleExpand = () => {
    this.setState(prevState => {
      return { expanded: !prevState.expanded }
    })
  }

  onKeyDown = e => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        this.toggleExpand()
        break
      default:
    }
  }

  render() {
    const expandIcon = this.state.expanded ? (
      <StyledCollapse />
    ) : (
      <StyledExpand />
    )
    var secondaryArrayIsAllNull = true
    secondaryArrayIsAllNull = this.props.actionsSecondary.every(e =>
      e === null ? true : false
    )

    return (
      <>
        <TitleDefault bg="accent.0" py={[1, 2]} pl={3}>
          <Text
            as="h3"
            fontWeight="normal"
            fontSize={2}
            my={0}
            color="accent.4"
          >
            Play &nbsp;
            <Text color="accent.2">/</Text>
            &nbsp; Download
          </Text>
        </TitleDefault>

        {this.props.actions}

        {secondaryArrayIsAllNull === false && (
          <TitleMoreSection
            className="more-title-section"
            bg="accent.0"
            py={2}
            px={3}
            role="button"
            aria-expanded={this.state.expanded}
            aria-controls="more-stores-section"
            id="more-stores-expandable"
            tabIndex={0}
            onClick={this.toggleExpand}
            onKeyDown={this.onKeyDown}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <TitleMore
                as="h3"
                fontWeight="normal"
                fontSize={2}
                my={0}
                color="accent.4"
              >
                More
              </TitleMore>

              {expandIcon}
            </Flex>
          </TitleMoreSection>
        )}

        {this.state.expanded && (
          <Box
            role="region"
            aria-labelledby="more-stores-expandable"
            id="more-stores-section"
          >
            {this.props.actionsSecondary}
          </Box>
        )}
      </>
    )
  }
}
