import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Box } from '@primer/components'

import MusicAction from './MusicAction'
import MusicActionsList from './MusicActionsList'

const createActions = (musicStores, songLinks) => {
  return musicStores.map((musicStore, i) => {
    const songLink = songLinks[musicStore.ref]
    if (!songLink) return null
    const firstItem = i === 0

    return (
      <MusicAction
        key={musicStore.ref + i}
        platform={musicStore.name}
        icon={musicStore.icon}
        action={musicStore.action}
        url={songLink}
        firstItem={firstItem}
      />
    )
  })
}

const MusicItemActions = ({ songLinks }) => (
  <StaticQuery
    query={graphql`
      query MusicStoresQuery {
        allLinksYaml {
          nodes {
            name
            icon
            ref
            action
            musicOrder
            secondary
            linkType
          }
        }
      }
    `}
    render={data => {
      let musicStores = data.allLinksYaml.nodes
        .filter(node => node.linkType === 'music')
        .sort((a, b) => a.musicOrder - b.musicOrder)
      // Split into 2 arrays of primary and secondary items
      let musicStoresSecondary = musicStores.filter(node => node.secondary)
      musicStores = musicStores.filter(node => !node.secondary)

      const actions = createActions(musicStores, songLinks)
      const actionsSecondary = createActions(musicStoresSecondary, songLinks)

      return (
        <Box>
          <MusicActionsList
            actions={actions}
            actionsSecondary={actionsSecondary}
          />
        </Box>
      )
    }}
  />
)

export default MusicItemActions
