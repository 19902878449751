import React from 'react'
import { themeGet, Box, Flex, Text, Link as PrimerLink } from '@primer/components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import GetApp from '@material-ui/icons/GetApp'

import theme from '../../../config/theme'

const ActionButtonStyles = styled(Flex)`
  appearance: none;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  line-height: 38px;
  white-space: nowrap;
  min-width: 110px;
  height: 30px;
  padding: 0px 5px;
  border-radius: ${themeGet('radii.2')};
  font-size: 0.875rem;
  flex-shrink: 0;
  color: ${themeGet('colors.light')};
  background-color: ${themeGet('colors.bodytext')};
  transition: all 100ms ease;
  user-select: none;
  overflow: hidden;
  outline: none;

  @media (min-width: ${themeGet('breakpoints.2')}) {
    min-width: 120px;
    height: 35px;
    padding: 0px 10px;
  }
`

const NoUnderlinePrimerLink = styled(PrimerLink)`
  :hover {
    text-decoration: none;
  }
`

const HoverRow = styled(Box)`
  transition: all 100ms ease;

  :hover {
    background: ${themeGet('colors.accent.0')};

    .action-button {
      background: ${themeGet('colors.primary')};
    }
  }
`

const StyledActionIcon = styled(Box)`
  transform: scale(0.6);

  @media (min-width: ${themeGet('breakpoints.2')}) {
    transform: scale(0.75);
  }
`

const ActionButton = ({ action, icon }) => (
  <ActionButtonStyles
    className="action-button"
    justifyContent="start"
    alignItems="center"
  >
    <StyledActionIcon as={icon} mr={1} /> {action}
  </ActionButtonStyles>
)

const MusicAction = ({ platform, icon, action, url, firstItem }) => {
  const actionButton =
    action === 'play' ? (
      <ActionButton action="Play" icon={PlayCircleFilled} />
    ) : (
      <ActionButton action="Download" icon={GetApp} />
    )

  const topBorder = firstItem
    ? {}
    : { borderTop: `1px solid ${theme.colors.accent[1]}` }

  return (
    <NoUnderlinePrimerLink href={url} target="_blank" rel="noopener noreferrer">
      <HoverRow style={topBorder} py={3} pl={[0, 0, 0, 3]}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex
            as={Text}
            alignItems="center"
            color="bodytext"
            fontWeight="bold"
            fontSize={3}
            style={{ letterSpacing: '0.02em' }}
          >
            <Text
              as={FontAwesomeIcon}
              icon={['fab', icon]}
              fontSize={[3, 3, 3, 4]}
              fixedWidth
              color="accent.2"
              mr={2}
            />
            {platform}
          </Flex>
          {actionButton}
        </Flex>
      </HoverRow>
    </NoUnderlinePrimerLink>
  )
}

export default MusicAction
